
          @import "src/styles/_animation.scss";
          @import "src/styles/_colors.scss";
          @import "src/styles/_container.scss";
					@import "src/styles/_font-mixins.scss";
					@import "src/styles/_fonts.scss";
					@import "src/styles/_functions.scss";
					@import "src/styles/_mixins.scss";
				  @import "src/styles/_variables.scss";
        
.call-to-action {
  background-color: $clr-primary-td-green-tint;
  padding: su(8) su(3);
}

.call-to-action__inner {
  @include container(false, false);
  text-align: center;
}

.call-to-action__text {
  @include sf-style($font-size: 22px, $font-family: $ff-td-medium, $font-weight: $fw-semi-bold, $line-height: 1.45);
  margin-bottom: su(3);
  margin-left: auto;
  margin-right: auto;
  max-width: rem(755px);
}

.call-to-action__button {
  @include button;
  margin-left: auto;
  margin-right: auto;
}