
          @import "src/styles/_animation.scss";
          @import "src/styles/_colors.scss";
          @import "src/styles/_container.scss";
					@import "src/styles/_font-mixins.scss";
					@import "src/styles/_fonts.scss";
					@import "src/styles/_functions.scss";
					@import "src/styles/_mixins.scss";
				  @import "src/styles/_variables.scss";
        
.closed-contest__title {
  text-align: center;
  margin-bottom: rem(200px);
}