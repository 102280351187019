
          @import "src/styles/_animation.scss";
          @import "src/styles/_colors.scss";
          @import "src/styles/_container.scss";
					@import "src/styles/_font-mixins.scss";
					@import "src/styles/_fonts.scss";
					@import "src/styles/_functions.scss";
					@import "src/styles/_mixins.scss";
				  @import "src/styles/_variables.scss";
        
.notification {
  margin-top: auto;
  margin-bottom: auto;

  &--success {
    .notification__title {
      color: $green-primary;
    }
  }

  &--error {
    .notification__title {
      color: $error-state;
    }
  }
}

.notification__inner {
  @include container(false, false);
}

.notification__content {
  margin-left: auto;
  margin-right: auto;
  padding: su(10) su(3);
  text-align: center;
}

.notification__title {
  @include notification-title;
  @extend %u-animate-font;
  margin-bottom: rem(26px);
  margin-top: 0;
}

.notification__icon {
  flex: 0 0 56px;
  margin-right: rem(14px);
  max-width: 56px;

  svg {
    display: inline-block;
    height: 48px;
    vertical-align: middle;
    width: 56px;
  }
}

.notification__heading {
  @include notification-heading;
  @extend %u-animate-font;
  align-items: center;
  color: $green-dark;
  display: flex;
  justify-content: center;
  margin-bottom: su(8);
}

.notification__text {
  @include notification-text;
  color: $black-text;
}