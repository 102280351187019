// Site font style.
@mixin sf-style($font-size, $font-family: $ff-td-semi-light, $font-weight: $fw-regular, $line-height: 1.5, $font-full-size: false) {
  -webkit-font-smoothing: antialiased;
  font-family: $font-family;
  font-size: $font-size + 0;
  font-weight: $font-weight;
  line-height: $line-height;

  @if $font-full-size {
    @include media-bp-min(md) {
      font-size: $font-full-size;
    }
  }
}

@mixin box-shadow {
  border-bottom: 1px solid $gray-light;
  box-shadow: 0 2px 4px rgb(204 204 204 / 50%);
}

@mixin button {
  @include button-label;
  @extend %u-animate-all;
  align-items: center;
  background: $orange-action;
  border: rem(1px) solid transparent;
  box-sizing: border-box;
  color: $black-text;
  display: flex;
  justify-content: center;
  min-height: rem(48px);
  padding: su(1) su(4);
  position: relative;
  text-decoration: none;
  width: fit-content;

  &:hover,
  &:focus,
  &:active {
    color: #1c1c1c;
    background-color: #e88903;
    border-color: #e88903;
  }

  &:focus {
    box-shadow: 0 0 0 3px #fff, 0 0 0 5px #ff9500;
    outline: 0;
  }
}

@mixin link {
  @include link-label;
  @extend %u-animate-all;
  color: $green-primary;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
}

@mixin description-link {
  @include description;
  @extend %u-animate-all;
  color: $green-primary;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
}