@import "fonts";

@mixin body-text {
  @include sf-style($font-size: 14px, $font-family: $ff-td-semi-light, $font-weight: $fw-regular, $line-height: 15px);
}

@mixin body-strong-text {
  @include sf-style($font-size: 14px, $font-family: $ff-td-medium, $font-weight: $fw-extra-bold, $line-height: 28px);
}

@mixin input-text {
  @include sf-style($font-size: 15px, $font-family: $ff-td-medium, $font-weight: $fw-semi-bold, $line-height: 1.6);
}

@mixin select-text {
  @include sf-style($font-size: 15px, $font-family: $ff-td-medium, $font-weight: $fw-regular, $line-height: 23px);
}

@mixin header-slogan {
  @include sf-style($font-size: 17px, $font-family: $ff-td-medium, $font-weight: $fw-bold, $line-height: 20px);
}

@mixin banner-title {
  @include sf-style($font-size: 60px, $font-family: $ff-td-medium, $font-weight: $fw-semi-bold, $line-height: 1.2);
}

@mixin h2-large {
  @include media-bp-min(tb) {
    @include sf-style($font-size: 48px, $font-family: $ff-td-medium, $font-weight: $fw-semi-bold, $line-height: 1.5);
  }

  @include sf-style($font-size: 44px, $font-family: $ff-td-medium, $font-weight: $fw-semi-bold, $line-height: 1.18);
}

@mixin h2 {
  @include media-bp-min(tb) {
    @include sf-style($font-size: 35px, $font-family: $ff-td-medium, $font-weight: $fw-semi-bold, $line-height: 1.48);
  }

  @include sf-style($font-size: 32px, $font-family: $ff-td-medium, $font-weight: $fw-semi-bold, $line-height: 1.4);
}

@mixin button-label {
  @include sf-style($font-size: 16px, $font-family: $ff-td-medium, $font-weight: $fw-semi-bold, $line-height: 1.25);
}

@mixin button-label-bold {
  @include sf-style($font-size: 16px, $font-family: $ff-td-medium, $font-weight: $fw-semi-bold, $line-height: 1.25);
}

@mixin input-label {
  @include sf-style($font-size: 15px, $font-family: $ff-td-semi-light, $font-weight: $fw-regular, $line-height: 1.6);
}

@mixin link-label {
  @include sf-style($font-size: 15px, $font-family: $ff-td-semi-light, $font-weight: $fw-semi-bold, $line-height: 24px);
}

@mixin description {
  @include sf-style($font-size: 12px, $font-family: $ff-td-semi-light, $font-weight: $fw-regular, $line-height: 14px);
}

@mixin rules-text {
  @include sf-style($font-size: 11px, $font-family: $ff-td-semi-light, $font-weight: $fw-regular, $line-height: 1.45);
}

@mixin form-error-message {
  @include sf-style($font-size: 13px, $font-family: $ff-td-medium, $font-weight: $fw-regular, $line-height: 20px);
}

@mixin notification-heading {
  @include sf-style($font-size: 35px, $font-family: $ff-td-medium, $font-weight: $fw-semi-bold, $line-height: 1.48);
}

@mixin notification-title {
  @include media-bp-min(tb) {
    @include sf-style($font-size: 48px, $font-family: $ff-td-medium, $font-weight: $fw-semi-bold, $line-height: 1.25);
  }

  @include sf-style($font-size: 35px, $font-family: $ff-td-medium, $font-weight: $fw-semi-bold, $line-height: 1.25);
}

@mixin form-field-description {
  @include sf-style($font-size: 11px, $font-family: $ff-td-semi-light, $font-weight: $fw-regular, $line-height: 1.45);
  font-style: italic;
}

@mixin notification-text {
  @include sf-style($font-size: 26px, $font-family: $ff-td-medium, $font-weight: $fw-semi-bold, $line-height: 1.38);
}

@mixin small-link {
  @include sf-style($font-size: 15px, $font-family: $ff-td-semi-light, $font-weight: $fw-regular, $line-height: 1.2);
}