$green-secondary: #F6FAF0;
$green-primary-tint: #F9FCF4;
$green-dark: #1A5336;
$green-text-primary: #038203;
$green-primary: #008A00;
$green-light: #B4C797;
$black-text: #1C1C1C;
$black: #000000;
$outline-color: #12422b;
$orange-action: #FF9500;
$orange-hover: #E37609;
$orange-clicked: #ECA42E;
$white: #FFFFFF;
$gray: #616161;
$gray-light: #CCCCCC;
$gray-primary-tint: #F9F9F9;
$border-color: #8C8C8C;
$error-state: #AE1100;
$error: #f7e8e9;
$alert: #FDF2DF;
$gray-v2: #767676;
$gray-v3: #404041;

$clr-primary-grey-tint: #F9F9F9;
$clr-secondary-grey-tint: #F3F3F3;
$clr-td-primary-green: #008A00;
$clr-grey-light: #CCCCCC;
$clr-td-primary-text-green: #038203;
$clr-primary-td-green-tint: #F9FCF4;
$clr-form-label: #8C8C8C;
$clr-td-dark-green: #1A5336;