
          @import "src/styles/_animation.scss";
          @import "src/styles/_colors.scss";
          @import "src/styles/_container.scss";
					@import "src/styles/_font-mixins.scss";
					@import "src/styles/_fonts.scss";
					@import "src/styles/_functions.scss";
					@import "src/styles/_mixins.scss";
				  @import "src/styles/_variables.scss";
        
.header {
  @include box-shadow;
  position: relative;
}

.header__main {
  background-color: $white;
}

.header__inner {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: rem(30px);
}

.header__logo {
  line-height: 0;
}

.header__logo-link {
  display: block;
}
