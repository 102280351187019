
          @import "src/styles/_animation.scss";
          @import "src/styles/_colors.scss";
          @import "src/styles/_container.scss";
					@import "src/styles/_font-mixins.scss";
					@import "src/styles/_fonts.scss";
					@import "src/styles/_functions.scss";
					@import "src/styles/_mixins.scss";
				  @import "src/styles/_variables.scss";
        
.student-form__inner {
  @include media-bp-min(tb) {
    padding-bottom: su(12.75);
  }

  @include container(false, false, col10);
  margin: 0 auto;
  padding-bottom: su(3);
  padding-left: su(3);
  padding-right: su(3);
}

legend {
  font-family: $ff-td-medium;
}

hr {
  width: 100%;
  height: 1px;
  border: none;
  background-color: #cccccc;
}

.student-form__title {
  @include media-bp-min(tb) {
    margin-bottom: rem(20px);
  }

  @include h2-large;
  @extend %u-animate-font;
  color: $clr-td-primary-green;
  margin-bottom: rem(20px);
  margin-top: 0;
  text-align: center;
}

.student-form__subtitle {
  @include h2;
  @extend %u-animate-font;
  color: $green-dark;
  margin-bottom: su(6);
  margin-top: 0;
  text-align: center;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.student-form__required-fields {
  margin-bottom: rem(48px);
  font-size: rem(13px);
}
.student-form__submit {
  @include button;
}

.student-form__form {
  margin-left: auto;
  margin-right: auto;
  max-width: rem(880px);
}

.student-form__form-items {
  @include media-bp-min(tb) {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: su(5);
  }

  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: su(3);
}

.student-form__text {
  @include sf-style($font-size: 13px, $font-family: $ff-td-semi-light, $font-weight: $fw-regular, $line-height: 1.84);
  color: #404041;
  margin-top: su(3);
}

.student-form__rules-text {
  @include rules-text;
  color: $gray-v3;
  max-width: rem(875px);
  margin-bottom: rem(48px);

  a {
    color: $green-text-primary;
    font-weight: $fw-bold;
    word-break: break-all;
  }
  span {
    font-weight: 600;
  }
}

.student-form__bottom-text {
  @include rules-text;
  color: $gray-v3;
  margin-top: su(6);
  text-align: center;

  p {
    margin-bottom: su(1);

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.student-form__form-item {
  &--full {
    @include media-bp-min(tb) {
      grid-column: 1/3;
    }
  }

  &--centered {
    text-align: center;
  }

  textarea,
  input[type="text"],
  input[type="email"] {
    @include input-text;
    border: rem(1px) solid $border-color;
    border-color: $border-color;
    color: $black-text;
    outline: 0;
    padding: rem(3px) rem(15px);
    width: 100%;

    &:focus,
    &:hover {
      border-bottom-width: rem(3px);
      padding-bottom: rem(1px);
      color: $black-text;
      background-color: $white;
      border-bottom-color: $green-primary;
      outline: 0;
      box-shadow: none;
    }
  }

  textarea {
    padding-bottom: 15px;
    padding-top: 15px;
    resize: none;

    &:focus,
    &:hover {
      padding-bottom: 13px;
      padding-top: 15px;
    }
  }

  input[type="text"],
  input[type="email"] {
    min-height: rem(48px);
  }

  &.error {
    textarea,
    input[type="text"],
    input[type="email"] {
      color: $error-state;
      border-bottom-width: 3px;
      padding-bottom: 2px;
      border-color: $border-color;
      border-bottom-color: $error-state;
      background-image: none;
    }
  }

  select {
    @include input-text;
    border-color: $border-color;
    color: $black-text;
    outline: 0;
    border-width: 1px;
    padding: 3px 15px;
    width: 100%;
    height: 42px; // Since your text and email inputs have this height
    appearance: none; // To remove the default styling
    background: $white url('../../assets/dropdown.svg') no-repeat right 10px center; // You can add a custom arrow if you wish

    &:focus,
    &:hover {
      border-bottom-width: 3px;
      padding-bottom: 1px;
      color: $black-text;
      background-color: $white;
      border-bottom-color: $green-primary;
      outline: 0;
      box-shadow: none;
    }
  }

  &.error {
    select {
      color: $error-state;
      border-bottom-width: 3px;
      padding-bottom: 2px;
      border-color: $border-color;
      border-bottom-color: $error-state;
    }
  }

  legend {
    @include input-label;
    margin-bottom: rem(10px);
  }
}

.student-form__label {
  @include input-label;
  color: $black-text;
  display: inline-block;
  margin-bottom: su(1);

  &--invisible {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }

  a {
    @include link;
  }

  &.form-required {
    align-items: flex-start;
    display: flex;
  }
}

.student-form__actions {
  margin-bottom: su(6);
  margin-top: su(6);
  text-align: center;

  .student-form__submit {
    cursor: pointer;
    margin: 0 auto;
  }
}

.student-form__checkbox {
  position: relative;
  display: inline-block;
  padding-left: 20px;

  input[type='checkbox'] {
    border-radius: 0;
    position: absolute;
    top: 3px;
    bottom: 0;
    left: 0;
    margin: 0;
    background-color: transparent;
    border: solid 2px $green-primary;
    width: 20px;
    height: 20px;
    padding: 0;
    outline: 0;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;

    &:checked {
      background-image: url('../../assets/checkbox.svg');
      background-size: 18px 18px;
      background-color: $green-primary;
      background-position: center;
    }

    &:focus {
      outline: 1px dotted $outline-color;
      outline-offset: 1px;
    }
  }

  label {
    @include input-label;
    cursor: pointer;
    display: inline-block;
    height: 100%;
    padding: rem(2px) 0 0 rem(15px);
    margin: 0;
  }

  &.error {
    input[type="checkbox"] {
      &:not(:checked) {
        border-color: $error-state;
        background-color: $error;
      }
    }
  }
}

.student-form__select-buttons-wrapper {
  padding: 0;
  margin: su(3) 0;
  border: 0;

  > legend {
    align-items: flex-start;
    display: flex;
  }
}

.student-form__select-buttons-group {
  @include media-bp-min(tb) {
    grid-gap: su(4.5);
    grid-template-columns: repeat(3, 1fr);
  }

  display: grid;
  grid-template-columns: 1fr;
  grid-gap: su(2);
  width: 100%;
}

.student-form__select-button-wrapper {
  position: relative;
  height: rem(55px);

  input[type='radio'] {
    position: absolute;
    top: 0;
    left: 0;
    border: none;
    background: 0 0;
    -webkit-appearance: none;
    -moz-appearance: none;

    &:focus + label {
      background-color: $green-primary-tint;

      &:before {
        bottom: 3px;
        left: 3px;
        opacity: .75;
        right: 3px;
        top: 3px;
      }
    }

    &:checked + label {
      color: $white;
      background: $green-text-primary;
    }

    &:checked:focus + label {
      &:before {
        border-color: $white;
      }
    }
  }

  label {
    @include button-label-bold;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    float: left;
    color: $black-text;
    cursor: pointer;
    background-color: transparent;
    text-align: center;
    border: rem(2px) solid $green-primary;
    border-radius: 0;
    padding: rem(14px) su(2);
    margin: 0;
    user-select: none;
    width: auto;
    min-height: rem(55px);
    min-width: 100%;
    height: 100%;
    -webkit-justify-content: center;
    -webkit-user-select: none;
    -webkit-box-align: center;
    -webkit-align-items: center;

    &:before {
      border-radius: 0;
      border: 2px solid $green-primary;
      bottom: -2px;
      content: '';
      display: block;
      left: -2px;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      right: -2px;
      top: -2px;
      transition: all 150ms ease-in-out;
    }
  }
}

.student-form__form-error {
  @include form-error-message;
  color: $error-state;
  padding-top: su(.5);

  .student-form__form-error-symbol {
    &:before {
      content: "! ";
    }
  }
}

.student-form__loader {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: su(12);
}

.student-form__description {
  @include form-field-description;
  color: $gray-v2;
  margin-top: su(1);
}

.student-form__rules-text-link {
  display: inline-block;
}

a.ot-sdk-show-settings {
  text-decoration: underline;
  cursor: pointer;
}

.required-field-indicator {
  margin-right: rem(4px);
  color: $error-state;;
}