
          @import "src/styles/_animation.scss";
          @import "src/styles/_colors.scss";
          @import "src/styles/_container.scss";
					@import "src/styles/_font-mixins.scss";
					@import "src/styles/_fonts.scss";
					@import "src/styles/_functions.scss";
					@import "src/styles/_mixins.scss";
				  @import "src/styles/_variables.scss";
        
@import "./styles/colors";
@import "./styles/font-mixins";
@import "./styles/fonts";
@import "./styles/variables";

html, body {
  @include body-text;
  box-sizing: border-box;
  color: $black-text;
  font-family: $ff-td-semi-light;
  font-size: calc($fs-base / 16px) * 100%;
  font-weight: $fw-regular;
  height: 100%;
  line-height: 24px;
  margin: 0;
}

* {
  box-sizing: border-box;
}

a {
  * {
    cursor: pointer;
  }

  object,
  svg {
    cursor: pointer;
  }
}

img {
  height: auto;
  max-width: 100%;
}

p {
  margin-bottom: 0;
  margin-top: 0;

  &:not(:last-child) {
    margin-bottom: su(2);
  }
}

#root {
  height: 100%;
}

.App {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#main-content {
  display: flex;
  flex: 1;
  flex-direction: column;
}

[data-lastpass-root] {
  display: none;
}