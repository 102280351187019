$ff-td-semi-light: 'TDGraphik-Semilight', sans-serif;
$ff-td-medium: 'TDGraphik-Medium', sans-serif;
$ff-td-semi-bold: 'TDGraphik-Semibold', sans-serif;
$ff-td-black: 'TDGraphik-Black', sans-serif;

@font-face {
  font-family: TDGraphik-Semilight;
  src: url('../assets/fonts/TDGraphik-Semilight-Web.eot') format('eot'),
  url('../assets/fonts/TDGraphik-Semilight-Web.woff') format('woff'),
  url('../assets/fonts/TDGraphik-Semilight-Web.woff2') format('woff2');
  font-weight: $fw-regular;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: TDGraphik-Medium;
  src: url('../assets/fonts/TDGraphik-Medium-Web.eot') format('eot');
  src: url('../assets/fonts/TDGraphik-Medium-Web.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/TDGraphik-Medium-Web.woff') format('woff'),
  url('../assets/fonts/TDGraphik-Medium-Web.woff2') format('woff2');
  font-weight: $fw-medium;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: TDGraphik-Semibold;
  src: url('../assets/fonts/TDGraphik-Semibold-Web.eot') format('eot');
  src: url('../assets/fonts/TDGraphik-Semibold-Web.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/TDGraphik-Semibold-Web.woff') format('woff'),
  url('../assets/fonts/TDGraphik-Semibold-Web.woff2') format('woff2');
  font-weight: $fw-semi-bold;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: TDGraphik-Black;
  src: url('../assets/fonts/TDGraphik-Black.eot') format('eot'),
  url('../assets/fonts/TDGraphik-Black.woff') format('woff'),
  url('../assets/fonts/TDGraphik-Black.woff2') format('woff2');
  font-weight: $fw-black;
  font-style: normal;
  font-display: fallback;
}
