
          @import "src/styles/_animation.scss";
          @import "src/styles/_colors.scss";
          @import "src/styles/_container.scss";
					@import "src/styles/_font-mixins.scss";
					@import "src/styles/_fonts.scss";
					@import "src/styles/_functions.scss";
					@import "src/styles/_mixins.scss";
				  @import "src/styles/_variables.scss";
        
.language-switcher {
  display: inline-block;
  position: relative;

  .language-switcher__current {
    @include media-bp-max(tb) {
      justify-content: center;
      width: 100%;
    }

    @include small-link;
    @extend %u-animate-color-background-color-border-color;
    align-items: center;
    background: $clr-primary-grey-tint;
    border: rem(1px) solid $clr-secondary-grey-tint;
    border-radius: rem(1px);
    color: $black-text;
    cursor: pointer;
    display: flex;
    height: rem(42px);
    padding: su(1.5) su(1.5) su(1.4);
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
      background-color: $clr-secondary-grey-tint;
    }

    &:focus {
      background-color: $clr-secondary-grey-tint;
      border-color: $clr-td-primary-green;
    }

    &:active {
      background-color: $clr-grey-light;
      border-color: rem(1px) solid $clr-form-label;
      color: $clr-td-dark-green;
    }
  }
}

.language-switcher__flag {
  margin-left: su(.5);

  svg {
    height: rem(11px);
    width: rem(16px);
  }
}

.language-switcher__dropdown {
  ul {
    padding-bottom: 0;
    padding-top: 0;

    > li {
      padding: 0;
    }
  }

  .language-switcher__item {
    display: block;

    .language-switcher__item-link {
      @extend %u-animate-color-background-color;
      border-bottom: rem(1px) solid $clr-secondary-grey-tint;
      border-left: rem(1px) solid $clr-secondary-grey-tint;
      border-right: rem(1px) solid $clr-secondary-grey-tint;

      &:hover {
        color: $clr-td-primary-text-green;
      }

      &:focus {
        background-color: $clr-primary-td-green-tint;
        color: $clr-td-primary-text-green;
      }

      &.is-active {
        background-color: $white;
      }
    }

    &:first-child {
      .language-switcher__item-link {
        border-radius: rem(1px) rem(1px) 0 0;
        border-top: rem(1px) solid $clr-secondary-grey-tint;
      }
    }

    &:last-child {
      .language-switcher__item-link {
        border-radius: 0 0 rem(1px) rem(1px);
      }
    }
  }

  .language-switcher__item-link {
    @include media-bp-max(tb) {
      justify-content: center;
      width: 100%;
    }

    @include media-bp-min(lg) {
      padding: su(1.5) su(1.5) su(1.4);
    }

    @include small-link;
    align-items: center;
    background: $clr-primary-grey-tint;
    color: $black-text;
    cursor: pointer;
    display: flex;
    padding: su(.8) rem(11px) su(.7);
    text-decoration: none;
    text-transform: uppercase;
  }
}